.Rsvp {
    margin: 50px auto 100px auto;
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.watercolor {
    width: 50%;
}

.watercolor-image {
    width: 100%;
}

.rsvp-form {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.bold {
    font-weight: bold;
}

.form-section {
    margin: 0 0;
}

.inputs {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0 10%;
}

label {
    text-align: center;
}

.guest-names {
    margin: 10px;
}

input {
    margin: 10px 5px;
}

input:focus, textarea:focus {
    outline: none;
}

input[type=text] {
    border: none;
    border-bottom: 2px solid black;
    font-family: 'Nanum Gothic', sans-serif;
    font-size: 16px;
    padding: 0 3px;
}

input[type=text]::-webkit-input-placeholder {
    font-family: 'Nanum Gothic', sans-serif;
    font-size: 16px;
}

.plus-sign {
    height: 25px;
    width: 25px;
    margin: 5px;
}

select {
    width: 50px;
    margin: 10px auto;
    background-color: black;
    color: white;
    border-style: none;
    border-radius: 0;
    font-family: 'Nanum Gothic', sans-serif;
    font-size: 16px;
}

select:focus {
    outline: none;
}

.select-rsvp {
    width: 115px;
}

.meal-question {
    text-align: center;
}

.meal-options {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 10px 0;
}

.name, .response, .guest-count, .meal-option, .diet {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
}

textarea {
    margin: 10px 0;
    border: solid black 2px;
    font-family: 'Nanum Gothic', sans-serif;
    font-size: 16px;
}

.submit-button {
    display: flex;
    justify-content: center;
}

.submit {
    text-align: center;
    padding: 15px;
    color: white;
    background-color: black;
    border-style: none;
    font-family: 'Nanum Gothic', sans-serif;
    font-size: 18px;
    border-radius: 10%;
}

@media screen and (max-width: 1125px) {
    .watercolor {
        display: none;
        width: 0%;
    }
    .rsvp-form {
        width: 100%;
    }
}

@media screen and (max-width: 414px) {
    .Rsvp {
        width: 85%;
    }
}
