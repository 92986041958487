.Travel {
    margin: 50px auto 100px auto;
}

.hotel {
    width: 70%;
    margin: 50px auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.hotel-image {
    width: 500px;
    margin: 0 10px;
}

.hotel-info {
    width: 400px;
    margin: 0 10px;
    padding: 30px 0;
    text-align: center;
}

.hotel-name:hover, .hotel-address:hover, .deadline:hover {
    color: #889bae;
}

.deadline {
    font-size: 12px;
    font-style: italic;
    margin: 10px;
}

@media screen and (min-width: 1025px) {
    .Travel {
        margin: 100px auto 100px auto;
    }
}

@media screen and (max-width: 768px) {
    .hotel {
        width: 80%;
        margin: 50px auto;
        justify-content: space-between;
    }
    .hotel-image {
        width: 400px;
        margin: 0;
    }
    .hotel-info {
        width: 450px;
        margin: 0;
        padding: 10px 0;
        text-align: center;
    }
    .hotel-address {
        font-size: 14px;
    }
}

@media screen and (max-width: 1100px) {
    .hotel {
        width: 80%;
        margin: 50px auto;
        display: flex;
        flex-direction: column;
    }
    .hotel a {
        width: 100%;
    }

    .hotel-image {
        width: 100%;
    }
    .hotel-info {
        width: 100%;
        padding: 10px 0;
        text-align: center;
    }
    .hotel-address {
        font-size: 14px;
    }
}