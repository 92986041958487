.Registry {
    margin: 50px auto 100px auto;
}

.registry {
    width: 70%;
    margin: 50px auto;
    text-align: center;
}

h3:hover {
    color: #889bae;
}

.zola-image {
    display: block;
    margin: 0 auto;
    width: 50%;
}

@media screen and (min-width: 1025px) {
    .Registry {
        margin: 100px auto 100px auto;
    }
}