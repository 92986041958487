body {
  font-family: 'Nanum Gothic', sans-serif;
}

.App {
  /* border: solid yellow 2px; */
  margin: 10% auto 0 auto;
}

.date {
  /* border: solid pink 2px; */
  margin: 0 auto;
  /* padding: 0; */
  font-family: 'Do Hyeon', sans-serif;
  font-size: 150px;
  letter-spacing: 15px;
  text-align: center;
}

.subtitle {
  /* border: solid blue 2px; */
  margin: 0 auto;
  letter-spacing: 15px;
  text-align: center;
}

.header:hover, .date:hover {
  color: #889bae;
}

.navbar {
  /* border: solid red 2px; */
  margin: 25px auto;
  /* display: none; */
}

a {
  text-decoration: none;
  color: black;
}

.navitem {
  /* border: solid orange 2px; */
  margin: 10px;
  text-align: center;
  list-style-type: none;
}

.navitem:hover {
  color: #889bae;
}

@media screen and (max-width: 1024px) {
  .App {
    margin: 20% auto 0 auto;
  }
}