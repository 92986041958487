.Events {
    margin: 50px auto 100px auto;
}

h4, p {
    margin: 5px
}

h3 {
    margin: 1px 1px 0 1px;
}

.ceremony-location {
    width: 70%;
    margin: 50px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ceremony-location-image {
    width: 50%;
    height: 50%;
}

.welcome-location-image {
    width: 60%;
    height: 60%;
}

.ceremony-event-info {
    width: 50%;
    text-align: center;
    padding: 10% 0px 0px 0px;
}

.welcome-event-info {
    width: 50%;
    text-align: center;
    padding: 2% 0px 0px 0px;
}

.reception-location {
    width: 70%;
    margin: 100px auto;
}

.reception-location-image {
    width: 100%;
}

.reception-event-info {
    text-align: center;
}

.location-name:hover, .location-address:hover {
    color: #889bae;
}

.date-time-bundle {
    margin: 15px 1px 1px 1px;
}

.trolley {
    padding: 20px;
    font-size: 12px;
    font-style: italic;
    margin: 10px;
    line-height: 25px;
}

@media screen and (min-width: 1025px) {
    .Events {
        margin: 100px auto 100px auto;
    }
}

@media screen and (max-width: 900px) {
    .ceremony-location {
        width: 70%;
        margin: 50px auto;
        display: flex;
        flex-direction: column;
    }
    .ceremony-location-image, .welcome-location-image {
        width: 100%;
    }
    .ceremony-event-info, .welcome-event-info {
        width: 100%;
        text-align: center;
        padding: 0px;
    }
}